import { Button } from "$/components/ui/button";

export function Logout() {
  const host = import.meta.env.VITE_APP_URL;

  const logoutUrl = import.meta.env.DEV
    ? "http://localhost:3000/auth/logout"
    : `https://${host}/auth/logout`;

  return (
    <Button as="a" href={logoutUrl} variant="destructive" target="_self">
      Logout
    </Button>
  );
}
