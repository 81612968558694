import { Logout } from "$/components/login/logoutButton";
import { ThemeToggle } from "$/components/mode-toggle";
import { siteConfig } from "$/config/site";
import { Title } from "@solidjs/meta";

export default function Home() {
  return (
    <main>
      <Title>{siteConfig.title} - Home</Title>
      <ThemeToggle />
      <Logout />
    </main>
  );
}
